import Service from "./Service";
const baseurl = '/api/historia-riesgo-preconcepcional';
import { isEmpty } from "lodash";
export default {
    index(id, pagination = {},search=''){
        let url = `${baseurl}/${id}?`;
        
        url += search !== '' ? `s=${search}` : '';
        url += (!isEmpty(pagination)) ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}` : ''

        return Service.get(url);
    },
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
}